<template>
  <div class="relative z-10 space-y-12 seeroo-media-page">
    <h3 class="media-page-title" v-if="$route.query.ptitle != undefined">
      <router-link v-tooltip.top="'Буцах'" to="/">
        <i class="pi pi-arrow-circle-left"></i>
      </router-link>
      <span>{{ $route.query.ptitle }}</span>
    </h3>
    <router-view/>
    <div class="media-list">
      <div v-if="page == 1 && isLoading" class="media-loader-wrap">
        <div class="seeroo-loader">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>

      <div v-else class="media-grid-wrapper">
        <div class="seeroo-grid grid gap-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 cs-style-3">
          <div v-for="item in mediaList" :key="item.id" class="media-item">
            <router-link :to="`/theatre/detail/${item.id}`" class="media-cover">
              <a href="javascript:void(0)" class="card__play">
                <i class="isax isax-play"></i>
              </a>
              <p class="media-cover-title">{{ item.title }}</p>
            </router-link>
            <span v-if="item.is_free" class="card__rate card__rate--green price">Үнэгүй</span>
            <img :src="Poster" v-lazy:background-image="imageUrl(item.poster_hz, true)" class="poster rounded-md"/>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasMore && !isLoading" class="pagination">
      <ProgressSpinner v-if="isLoadingMore" style="width:50px;height:50px" strokeWidth="3" animationDuration="1s"/>
      <Button v-else type="button" @click="getMore(this.page + 1)">
        <span>Цааш</span>
        <i class="pi pi-angle-double-down"></i>
      </Button>
    </div>
  </div>
</template>

<script>
import useDevice from "@hooks/useDevice";
import Poster from "../../assets/poster-hz.png";
import {imageUrl} from "../../utils";
import {setModalActive} from "../../store";
import {mapGetters} from "vuex";

export default {
  components: {},
  setup() {
    const {isDesktop} = useDevice();
    return {isDesktop};
  },
  data() {
    return {
      isLoading: true,
      isLoadingMore: false,
      cat: null,
      hasMore: false,
      page: 1,
      Poster
    }
  },

  computed: {
    ...mapGetters(['mirror']),
    mediaList() {
      return this.$store.state['mediaList'];
    }
  },

  created() {
    let payload = {
      vm: this,
      cat: this.$route.params.cat ? this.$route.params.cat : 'all',
      season: this.$route.params.season ? this.$route.params.season : 'all',
      animeStatus: this.$route.params.status ? this.$route.params.status : 'all',
      sort: this.$route.params.sort ? this.$route.params.sort : 'all',
      page: this.page,
      type: this.$route.query.t ? this.$route.query.t : 'default',
    }

    this.$store.dispatch('getMediaList', payload);
  },

  mounted() {
    /** Used Only For Touch Devices **/
    (function (window) {

      // for touch devices: add class cs-hover to the figures when touching the items
      if (window.Modernizr.touch) {

        // classie.js https://github.com/desandro/classie/blob/master/classie.js
        // class helper functions from bonzo https://github.com/ded/bonzo

        let classReg = (className) => {
          return new RegExp("(^|\\s+)" + className + "(\\s+|$)");
        }

        // classList support for class management
        // altho to be fair, the api sucks because it won't accept multiple classes at once
        var hasClass, addClass, removeClass;

        if ('classList' in document.documentElement) {
          hasClass = (elem, c) => {
            return elem.classList.contains(c);
          };
          addClass = (elem, c) => {
            elem.classList.add(c);
          };
          removeClass = (elem, c) => {
            elem.classList.remove(c);
          };
        } else {
          hasClass = function (elem, c) {
            return classReg(c).test(elem.className);
          };
          addClass = function (elem, c) {
            if (!hasClass(elem, c)) {
              elem.className = elem.className + ' ' + c;
            }
          };
          removeClass = (elem, c) => {
            elem.className = elem.className.replace(classReg(c), ' ');
          };
        }

        let toggleClass = (elem, c) => {
          var fn = hasClass(elem, c) ? removeClass : addClass;
          fn(elem, c);
        }

        var classie = {
          // full names
          hasClass: hasClass,
          addClass: addClass,
          removeClass: removeClass,
          toggleClass: toggleClass,
          // short names
          has: hasClass,
          add: addClass,
          remove: removeClass,
          toggle: toggleClass
        };

        // transport
        window.classie = classie;

        [].slice.call(document.querySelectorAll('ul.grid > li > figure')).forEach((el, i) => {
          console.log(i);
          el.querySelector('figcaption > a').addEventListener('touchstart', (e) => {
            e.stopPropagation();
          }, false);
          el.addEventListener('touchstart', (e) => {
            console.log(e);
            classie.toggle(this, 'cs-hover');
          }, false);
        });

      }
    })(window);
  },

  watch: {
    '$route.params.cat'(to, from) {
      if (to !== from) {
        this.filterMedia();
      }
    },
    '$route.params.season'(to, from) {
      if (to !== from) {
        this.filterMedia();
      }
    },
    '$route.params.status'(to, from) {
      if (to !== from) {
        this.filterMedia();
      }
    },
    '$route.params.sort'(to, from) {
      if (to !== from) {
        this.filterMedia();
      }
    },
  },

  methods: {
    imageUrl,
    handleClick(animeID, epID) {
      if (this.mirror) {
        this.$router.push(`/watch/${animeID}/${epID}`)
      } else {
        this.$router.replace({query: {t: this.$route.query.t, media: animeID}});
        setModalActive(true);
      }
    },
    filterMedia() {
      this.isLoading = true;
      this.page = 1;

      let payload = {
        vm: this,
        cat: this.$route.params.cat ? this.$route.params.cat : 'all',
        season: this.$route.params.season ? this.$route.params.season : 'all',
        animeStatus: this.$route.params.status ? this.$route.params.status : 'all',
        sort: this.$route.params.sort ? this.$route.params.sort : 'all',
        page: this.page,
        type: this.$route.query.t ? this.$route.query.t : 'default'
      }

      this.$store.dispatch('getMediaList', payload);
    },
    getMore(page) {
      this.isLoadingMore = true;
      let payload = {
        vm: this,
        cat: this.$route.params.cat ? this.$route.params.cat : 'all',
        season: this.$route.params.season ? this.$route.params.season : 'all',
        animeStatus: this.$route.params.status ? this.$route.params.status : 'all',
        sort: this.$route.params.sort ? this.$route.params.sort : 'all',
        type: this.$route.query.t ? this.$route.query.t : 'default',
        page: page,
        isMore: true
      }

      this.$store.dispatch('getMediaList', payload)
    }
  }
};
</script>
<style>
</style>